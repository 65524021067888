<template>
  <div class="field-item">
    <div class="field-item__label">
      {{ itemVal.label }}
    </div>
    <selectWithKeyTemplate
      :settings="listSelectSettings"
      @changeSelect="changeListSelectSettings($event)"
    />
  </div>
</template>

<script>
import selectWithKeyTemplate from '@/components/fields/selectWithKeyTemplate';

export default {
  name: 'ListField',
  components: {
    selectWithKeyTemplate,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    listSelectSettings() {
      return {
        optionList: this.itemVal.value,
        placeholderText: '',
        selectedKey: this.itemVal.activeValue,
        disable: false,
        toTop: false,
        toTopFixed: true,
        tabindex: 1,
        selectClass: '',
      };
    },
    itemVal: {
      get() {
        return this.item;
      },
      set(data) {
        this.$emit('update:item', data);
      },
    },
  },
  methods: {
    changeListSelectSettings(key) {
      this.itemVal.activeValue = key;
    },
  },
};
</script>

<style lang="scss">
@import "../../sass/_variables.scss";
@import "../../sass/fields";
</style>
